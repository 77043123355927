





























































































































































































































import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'
import CourseData from '~/components/course/course-data'
import {Course, CourseEditStatus, CourseStatus, courseStatusOptions} from '~/components/data-class/data-class'
import {SubjectList} from '@afterschool.dev/as-data-admin'
import PaginationMixin from '~/mixins/pagination-mixin'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import {checkPermissions} from '~/utils/check-permission'
import {createRequest, serverBaseURL} from '~/utils/network-request'
import {CoursePermission} from '~/utils/permissions'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Course List'
        }
    }
})

export default class CourseList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = CoursePermission.ViewCourse
    canEdit = false

    CourseStatus = CourseStatus
    courseSubject = SubjectList.offered.map(sub => ({
        _id: sub._id,
        tag: sub.tag
    }))

    selectClass = {
        [CourseStatus.PENDING]: 'select-pending',
        [CourseStatus.PUBLISHED]: 'select-published',
        [CourseStatus.HIDDEN]: 'select-hidden',
        [CourseStatus.PREORDERING]: 'select-pre-ordering',
        [CourseStatus.PRIVATE]: 'select-private',
    }

    queryDef = [
        {localVar: 'selectedStatus', queryStr: 'status', type: QueryType.Number},
        {localVar: 'selectedCourseSubject', queryStr: 'subject'},
        {localVar: 'selectedInstructor', queryStr: 'instructor'},
        {localVar: 'keyword', queryStr: 'keyword'},
        {localVar: 'selectedEditStatus', queryStr: 'edit-status'},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    selectedCourseSubject = ''

    selectedInstructor = ''

    deleteDialogVisible: boolean = false

    keyword = ''

    selectedEditStatus = ''

    selectedStatus = -1

    selectedCourseType = 0

    statusOptions = courseStatusOptions

    courseEditStatusTag = {
        [CourseEditStatus.SUBMITTED]: {
            type: 'warning',
            label: 'Submitted',
            value: CourseEditStatus.SUBMITTED
        },
        [CourseEditStatus.PASSED]: {
            type: 'success',
            label: 'Passed',
            value: CourseEditStatus.PASSED
        },
        [CourseEditStatus.REJECTED]: {
            type: 'danger',
            label: 'Rejected',
            value: CourseEditStatus.REJECTED
        },
        [CourseEditStatus.DRAFT]: {
            type: 'info',
            label: 'Draft',
            value: CourseEditStatus.DRAFT
        }
    }

    tableData: Course[] = []

    tempPrice: number = 0

    tempStatus: CourseStatus = CourseStatus.PENDING

    deleteCourseData: { title: string, _id: string } = {title: '', _id: ''}
    deleteFns: string[] = []

    showDeleteCourseAlert(rowData) {
        this.deleteDialogVisible = true
        this.deleteCourseData = rowData
    }

    tableRowClassName({row}) {
        switch (row.edit_status) {
            case CourseEditStatus.SUBMITTED:
                return 'submitted-course'
            case CourseEditStatus.DRAFT:
                return 'draft-course'
            default:
                return ''
        }
    }

    get domainAdminV() {
        return process.env.VUE_APP_DOMAIN_ADMINV
    }

    async deleteCourse() {
        this.deleteDialogVisible = false
        if (!await checkPermissions(CoursePermission.EditCourse)) {
            alert(`Permission ${CoursePermission.EditCourse} is needed`)
            return
        }

        const course_id = this.deleteCourseData._id
        createRequest(`/course/${course_id}`, 'delete').send()
            .then((res) => {
                this.$message({
                    message: 'Deleted course successfully!',
                    type: 'success'
                })
                if (this.tableData.length === 1 && this.currentPage > 1)
                    this.currentPage--
                this.updateCourses(this.currentPage)
            })
            .catch((e) => {
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }

    // Change Price
    async changedDiscountedPrice(index, row) {
        if (!await checkPermissions(CoursePermission.EditCourse)) {
            alert(`Permission ${CoursePermission.EditCourse} is needed`)
            return
        }

        const body: {} = {
            discounted_price: row.discounted_price
        }

        const course_id = row._id
        createRequest(`/course/${course_id}/price`, 'patch', {}, body).send()
            .then((res) => {
                this.tempPrice = row.discounted_price
                this.$message({
                    message: 'Course price changed successfully!',
                    type: 'success'
                })
                this.updateCourses(this.currentPage)
            })
            .catch((e) => {
                row.discounted_price = this.tempPrice
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }

    priceInputFocus(index, row) {
        this.tempPrice = row.discounted_price
    }

    priceInputBlurred(index, row) {
        row.discounted_price = this.tempPrice
    }

    // Change Status
    async statusChanged(row: Course) {

        if (!await checkPermissions(CoursePermission.EditCourse)) {
            alert(`Permission ${CoursePermission.EditCourse} is needed`)
            return
        }

        if (row.status === CourseStatus.HIDDEN) {

            try {
                const action = await this.$confirm('Are you sure you want to hide the course? Trial course messages will not be sent. Please make sure you know what you are doing!', {
                    title: 'Hide Course',
                    confirmButtonText: 'Hide',
                    cancelButtonText: 'Cancel',
                    confirmButtonClass: 'btn-danger',
                    lockScroll: false
                })
            } catch (e) {
                row.status = this.tempStatus
                return
            }
        }

        if ([CourseStatus.PUBLISHED, CourseStatus.PREORDERING, CourseStatus.PRIVATE].includes(row.status) &&
            (row.handling_fee < 0 || row.price < 0 || row.discounted_price < 0)) {
            alert('Please set handling fee/price/discounted price before publish')
            return
        }

        const body: {} = {
            status: row.status
        }

        const course_id = row._id
        createRequest(`/course/${course_id}/status`, 'patch', {}, body).send()
            .then((res) => {
                this.$message({
                    message: 'Course status changed successfully!',
                    type: 'success'
                })
            })
            .catch((e) => {
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }

    async created() {
        this.parseQuery()
        this.updateCourses(this.currentPage)
        this.canEdit = await checkPermissions(CoursePermission.EditCourse)
    }

    updateCourses(page = 1) {
        this.currentPage = page
        const params: { [key: string]: string } = {
            skip: (page - 1) * this.step + '',
            number: this.step + '',
            status: 'published,pending,hidden,preordering,private',
            offline: this.selectedCourseType + ''
        }

        if (this.selectedCourseSubject !== '') {
            params.subjects = this.selectedCourseSubject.toString()
        }

        if (this.selectedInstructor !== '') {
            params.instructor = this.selectedInstructor.toString()
        }

        if (this.keyword !== '') {
            params.keyword = this.keyword
        }

        if (this.selectedEditStatus !== '') {
            params.edit_status = this.selectedEditStatus
        }

        if (CourseStatus[this.selectedStatus]) {
            params.status = CourseStatus[this.selectedStatus].toLowerCase()
        }

        createRequest('/courses/admin', 'get', params).send()
            .then(async (res) => {
                this.tableData = res.data.courses
                this.totalCount = res.data['count']
                for (const c of this.tableData) {
                    await CourseData.updateCourse(c._id, c)
                }
                for (const course of this.tableData) {
                    await CourseData.updateCourse(course._id, course)
                }
                this.setDeleteFns()
            }).catch((e) => {
            console.error(e)
        })
        this.setQuery()
    }

    setDeleteFns() {
        for (const fn of this.deleteFns) {
            delete document[fn]
        }
        this.deleteFns = []
        for (const course of this.tableData) {
            const fn = `delete${course._id}`
            document[fn] = () => {
                if (confirm(`Sure to delete ${course.title}?`)) {
                    createRequest(`/course/${course._id}/d`, 'delete').send()
                        .then((res) => {
                            this.$message({
                                message: 'Deleted',
                                type: 'success'
                            })
                            if (this.tableData.length === 1 && this.currentPage > 1)
                                this.currentPage--
                            this.updateCourses(this.currentPage)
                        })
                        .catch((e) => {
                            this.$message({
                                message: 'Error occurred!',
                                type: 'error'
                            })
                        })
                }
            }
        }
    }

    async downloadTsv() {
        const fileLink = document.createElement('a')
        fileLink.href = serverBaseURL + 'courses/courses.tsv?auth=' + await this.$auth.getAccessToken()
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }

    async downloadCatalog() {
        const fileLink = document.createElement('a')
        fileLink.href = serverBaseURL + 'courses/admin-catalog.csv?auth=' + await this.$auth.getAccessToken()
        document.body.appendChild(fileLink)
        fileLink.click()
        document.body.removeChild(fileLink)
    }
}

